import { useEffect, useState } from "react";
import { Container, Nav, Navbar, Modal, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from 'axios';
import logoo from "../../Images/logoo.png";
import "./navbar.css";

const NavBar = () => {
  const { cartList } = useSelector((state) => state.cart);
  const [expand, setExpand] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [orderReference, setOrderReference] = useState('');
  const [orderDetails, setOrderDetails] = useState(null);
  const [error, setError] = useState('');

  // fixed Header
  function scrollHandler() {
    if (window.scrollY >= 100) {
      setIsFixed(true);
    } else if (window.scrollY <= 50) {
      setIsFixed(false);
    }
  }
  window.addEventListener("scroll", scrollHandler);


  const handleTrackOrder = async () => {
    try {
      const response = await axios.get('https://backend.corewhitemarketing.com/api/order/getOrderByReference', {
        params: { reference: orderReference }
      });

      console.log(response.data); // Debugging: Log the response data
      if (response.data.status === 'success') {
        setOrderDetails(response.data.data);
        setError('');
      } else {
        setError('Failed to fetch order details. Please check the reference number.');
      }
    } catch (error) {
      console.error('Error fetching order details:', error); // Debugging: Log the error
      setError('Failed to fetch order details. Please check the reference number.');
    }
  };


  // useEffect(()=> {
  //   if(CartItem.length ===0) {
  //     const storedCart = localStorage.getItem("cartItem");
  //     setCartItem(JSON.parse(storedCart));
  //   }
  // },[])
  return (
    <>
    <Navbar
      fixed="top"
      expand="md"
      className={isFixed ? "navbar fixed" : "navbar"}
    >
      <Container className="navbar-container">
        <Navbar.Brand to="/">
          {/* <ion-icon name="bag"></ion-icon> */}
          <img src={logoo} alt="Multimart Logo" className="logo" />
        </Navbar.Brand>
        {/* Media cart and toggle */}
        <div className="d-flex">
          <div className="media-cart">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="black"
              className="nav-icon"
            >
              <path
                fillRule="evenodd"
                d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                clipRule="evenodd"
              />
            </svg>
            <Link
              aria-label="Go to Cart Page"
              to="/cart"
              className="cart"
              data-num={cartList.length}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="black"
                className="nav-icon"
              >
                <path d="M2.25 2.25a.75.75 0 000 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 00-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 000-1.5H5.378A2.25 2.25 0 017.5 15h11.218a.75.75 0 00.674-.421 60.358 60.358 0 002.96-7.228.75.75 0 00-.525-.965A60.864 60.864 0 005.68 4.509l-.232-.867A1.875 1.875 0 003.636 2.25H2.25zM3.75 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM16.5 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z" />
              </svg>
            </Link>
          </div>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => {
              setExpand(expand ? false : "expanded");
            }}
          >
            <span></span>
            <span></span>
            <span></span>
          </Navbar.Toggle>
        </div>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-end flex-grow-1 pe-3">
            <Nav.Item>
              <Link
                aria-label="Go to Home Page"
                className="navbar-link"
                to="/"
                onClick={() => setExpand(false)}
              >
                <span className="nav-link-label">HOME</span>
              </Link>
            </Nav.Item>

            <Nav.Item>
              <Link
                aria-label="Go to Shop Page"
                className="navbar-link"
                to="/shop"
                onClick={() => setExpand(false)}
              >
                <span className="nav-link-label">SHOP</span>
              </Link>
            </Nav.Item>

            <Nav.Item>
              <Link
                aria-label="Go to Cart Page"
                className="navbar-link"
                to="/cart"
                onClick={() => setExpand(false)}
              >
                <span className="nav-link-label">CART</span>
              </Link>
            </Nav.Item>
            <Nav.Item>
                <Button variant="link" onClick={() => setShowModal(true)} className="navbar-link">
                  <span className="nav-link-label">TRACK ORDER</span>
                </Button>
              </Nav.Item>
            <Nav.Item className="expanded-cart">
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="black"
                className="nav-icon"
              >
                <path
                  fillRule="evenodd"
                  d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                  clipRule="evenodd"
                />
              </svg> */}
              <Link
                aria-label="Go to Cart Page"
                to="/cart"
                className="cart"
                data-num={cartList.length}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="black"
                  className="nav-icon"
                >
                  <path d="M2.25 2.25a.75.75 0 000 1.5h1.386c.17 0 .318.114.362.278l2.558 9.592a3.752 3.752 0 00-2.806 3.63c0 .414.336.75.75.75h15.75a.75.75 0 000-1.5H5.378A2.25 2.25 0 017.5 15h11.218a.75.75 0 00.674-.421 60.358 60.358 0 002.96-7.228.75.75 0 00-.525-.965A60.864 60.864 0 005.68 4.509l-.232-.867A1.875 1.875 0 003.636 2.25H2.25zM3.75 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM16.5 20.25a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z" />
                </svg>
              </Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    
    <Modal show={showModal} onHide={() => setShowModal(false)}>
    <Modal.Header closeButton>
      <Modal.Title>Track Your Order</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form onSubmit={(e) => { e.preventDefault(); handleTrackOrder(); }}>
        <Form.Group controlId="orderReference">
          <Form.Label>Order Reference Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your order reference number"
            value={orderReference}
            className="mb-3"
            onChange={(e) => setOrderReference(e.target.value)}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid order reference number.
          </Form.Control.Feedback>
        </Form.Group>
        {error && <p className="text-danger">{error}</p>}
        {orderDetails && (
          <div className="order-details">
            <h5>Order Details:</h5>
            <p><strong>Order Reference:</strong> {orderDetails._id}</p>
            <p><strong>Email:</strong> {orderDetails.customerEmail}</p>
            <p><strong>Total Amount:</strong> AED {orderDetails.totalAmount}.00</p>
            <p><strong>Status:</strong> {orderDetails.status}</p>
            <h6>Items:</h6>
            <ul>
              {orderDetails.items.map(item => (
                <li key={item.id}>
                  {item.name} - AED {item.price}.00 x {item.quantity}
                </li>
              ))}
            </ul>
          </div>
        )}
        <Button variant="primary" type="submit">Track Order</Button>
      </Form>
    </Modal.Body>
  </Modal>
</>
  );
};

export default NavBar;
